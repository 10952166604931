import React, { FC } from "react";
import memoize from "utils/memoize";
import Drawer from "component-drawer";
import SearchField from "container-search-field";
import { RHRSearchDrawerProps } from "./types";

const RHRSearchDrawer: FC<RHRSearchDrawerProps> = ({
  searchOpen,
  setSearchOpen,
  paperPropsStyle,
  searchBoxStyle
}) => {
  return (
    <Drawer
      id="desktop-search-popper"
      open={searchOpen}
      anchor={"right"}
      PaperProps={{
        style: {
          width: 380,
          ...paperPropsStyle
        }
      }}
      disableScrollLock
      onClose={() => setSearchOpen?.(false)}
    >
      <div
        style={{
          paddingLeft: 40,
          paddingRight: 40,
          paddingBottom: 40,
          paddingTop: 24,
          ...searchBoxStyle
        }}
      >
        <SearchField
          onClose={() => {
            setSearchOpen?.(false);
          }}
          isRHRSearchField={true}
        />
      </div>
    </Drawer>
  );
};

export default memoize(RHRSearchDrawer);
