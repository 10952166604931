import { useQuery } from "@apollo/client";
import RHHeartIcon from "@RHCommerceDev/icon-rh-heart";
import analyticsLoader from "@RHCommerceDev/analytics/loader";
import { cn } from "@RHCommerceDev/utils/cn";
import RHLogo from "@RHCommerceDev/component-rh-logo";
import NavigationLink from "@RHCommerceDev/container-inline-header/NavigationLink";
import CatalogNavigationDetails from "container-rhr-header/RHRCatalogNavigationDetails";
import { querySaleCatalogNavigation } from "@RHCommerceDev/graphql-client/queries/navigation";
import useBrand from "@RHCommerceDev/hooks-use-brand/useBrand";
import useLocale from "@RHCommerceDev/hooks-use-locale/useLocale";
import { useRhUserAtomValue } from "@RHCommerceDev/hooks/atoms";
import { useAppId } from "@RHCommerceDev/hooks/useAppId";
import { useCountry } from "@RHCommerceDev/hooks/useCountry";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import useSite from "@RHCommerceDev/hooks/useSite";
import RHRArrowIcon from "@RHCommerceDev/icon-arrow-rhr";
import RHChevronIcon from "@RHCommerceDev/icon-chevron";
import React, { FC, useCallback, useMemo, useRef } from "react";
import { handleKeyboardPressWithEvent } from "@RHCommerceDev/utils/accessibility";
import {
  CATALOG_NAV_CONCEPTS_ID,
  CATALOG_SALE_COLOR,
  CATALOG_SALE_NAME,
  catalogLinksToEnable,
  RHR_FADE_TIMEOUT_DURATION_MIN,
  BREAKPOINT_MD,
  BREAKPOINT_LG
} from "@RHCommerceDev/utils/constants";
import {
  Fade,
  Popper,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import memoize from "@RHCommerceDev/utils/memoize";
import yn from "yn";
import RHRSaleNavigation from "@RHCommerceDev/container-rhr-header/RHRSaleNavigation";
import { CatalogNavigationProps } from "./types";
import { Theme } from "@mui/material/styles";
import { useTailwindComponent } from "@RHCommerceDev/hooks-use-tailwind-component";
import { TailwindTypography } from "@RHCommerceDev/component-tailwind-typography";
import {
  Popover,
  PopoverContent
} from "@RHCommerceDev/unstyled-ui-components/Popover";
import { PopoverTrigger } from "@RHCommerceDev/unstyled-ui-components/Popover";

export const CatalogNavigation: FC<CatalogNavigationProps> = ({
  catalogNavigation,
  isDarkMode,
  onChangeSelectedNavItem,
  selectedNavItem,
  onCategoryDrawerClose,
  justify,
  isAem,
  color,
  disabled,
  showBackToRH = false,
  backToHomeLink,
  backtoRHLabel = "BACK TO RH", // Fallback text here
  styles
}) => {
  // const nextgenPg =" useIsoCookies(["nextgenpg"])?.nextgenpg";
  //defaulting it to NG
  const isTailwindComponentEnabled = useTailwindComponent();
  const Typography = isTailwindComponentEnabled
    ? TailwindTypography
    : MuiTypography;
  const nextgenPg = "true";
  const brand = useBrand();
  const theme = useTheme();
  const siteId = useSite();
  const env = useEnv();
  const isMdToLg = useMediaQuery<Theme>(theme =>
    theme.breakpoints.between(BREAKPOINT_MD, BREAKPOINT_LG)
  );

  const { isConcierge } = useAppId();
  const FEATURE_TAILWIND_COMPONENTS = yn(env?.FEATURE_TAILWIND_COMPONENTS);

  const { userType } = useRhUserAtomValue();
  const locale = useLocale();
  const countryCode = useCountry();
  const navListRef = useRef([]);

  const hasNoSubCategories: boolean | undefined = useMemo(
    () =>
      selectedNavItem?.childCategories?.every(
        child => !child?.childCategories?.length
      ),
    [selectedNavItem]
  );

  const hasNoSecondarySubCategories: boolean | undefined = useMemo(
    () =>
      selectedNavItem?.childCategories?.every(child => {
        return (
          !child?.childCategories?.length ||
          child.childCategories.every(
            secondaryChild => !secondaryChild?.childCategories?.length
          )
        );
      }),
    [selectedNavItem]
  );

  const themeColor = color
    ? color
    : isDarkMode
    ? theme.palette.common.white
    : theme.palette.common.black;

  const handleNavItemClick = (
    e: React.SyntheticEvent,
    navElement: NavigationElement,
    isEnabledLink: boolean
  ) => {
    const target = e?.target as HTMLElement;
    const id = (target as HTMLElement)?.id;
    if (disabled) return false;
    e?.stopPropagation?.();
    if (disabled) return false;
    if (!navElement?.childCategories?.length) {
      // If Nav Item dont have a child item then directly emit universal click event #BUGFIX/ERHR-108
      document?.body?.dispatchEvent(
        new CustomEvent("cta_click", {
          detail: {
            item: {
              isHeaderNavigation: true,
              topCat: navElement?.displayName,
              parentCat: navElement?.displayName,
              cat: navElement?.targetUrl,
              catURL: navElement?.targetUrl,
              class: target?.className,
              id: target?.id || id
            }
          }
        })
      );

      onCategoryDrawerClose();
      if (navElement?.linkType === "none") {
        window.open(`${navElement?.targetUrl}`, "_blank");
      } else {
        window.open(`${navElement?.targetUrl}`, "_self");
      }
    } else {
      if (
        isEnabledLink &&
        navElement?.displayName?.toUpperCase() !==
          CATALOG_SALE_NAME.toUpperCase()
      ) {
        onCategoryDrawerClose();
      } else {
        handleDrawerOpen(e?.currentTarget, navElement);
      }
    }
  };

  const handleDrawerOpen = useCallback(
    (selectedHTMLElement, navElement: NavigationElement) => {
      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector("#spa-root > *")! as HTMLElement,
          a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE
        )
      );

      if (selectedNavItem?.id === navElement.id) {
        return onChangeSelectedNavItem?.(null, null);
      }

      return onChangeSelectedNavItem?.(navElement, selectedHTMLElement);
    },
    [onChangeSelectedNavItem, selectedNavItem?.id]
  );

  const {
    data: { saleCatalogNavigation: saleDataCatalogNavigation } = {} as Query
  } = useQuery<Query>(querySaleCatalogNavigation, {
    variables: {
      filter: "sale",
      siteId,
      locale,
      countryCode,
      nextgenPg: nextgenPg || "false"
    }
  });

  const navigationList =
    [...(catalogNavigation?.childCategories as NavigationElement[])]?.map(
      mainElement => {
        const upperCaseName =
          mainElement &&
          mainElement.displayName &&
          typeof mainElement.displayName === "string"
            ? mainElement.displayName.toUpperCase()
            : "";
        if (upperCaseName === CATALOG_SALE_NAME.toUpperCase()) {
          return {
            ...mainElement,
            childCategories: saleDataCatalogNavigation?.childCategories,
            linkType: "none"
          };
        } else return mainElement;
      }
    ) ?? [];

  //minor improvement to avoid unnecessary classnames
  const justifyClasses = {
    "flex-start": "justify-start",
    "flex-end": "justify-end",
    center: "justify-center",
    "space-around": "justify-around",
    "space-evenly": "justify-evenly",
    "space-between": "justify-between"
  };

  if (FEATURE_TAILWIND_COMPONENTS) {
    return (
      <ul
        className={cn([
          `flex flex-nowrap ${isAem ? "w-1/2 mx-auto my-[14px]" : "!my-0"} p-0`,
          justifyClasses?.[justify as keyof typeof justifyClasses] ||
            "justify-between"
        ])}
        role="menubar"
        aria-label="Main"
      >
        {showBackToRH && backToHomeLink && (
          <span
            className={`cursor-pointer absolute left-[60px] xl:left-[100px] flex items-center top-[-5px]`}
          >
            <RHChevronIcon htmlColor={isDarkMode ? "#fff" : "#000"} />
            <a
              href={backToHomeLink}
              className={`ml-[5px] ${
                isDarkMode ? "text-white" : "text-black"
              } no-underline hidden hover:underline ${
                isDarkMode ? "hover:text-white" : "hover:text-black"
              } md:inline-block`}
            >
              {backtoRHLabel}
            </a>
          </span>
        )}
        {navigationList?.map(navElement => {
          const isSelected =
            selectedNavItem?.displayName == navElement?.displayName;

          let category;

          if (navElement?.targetUrl?.indexOf(".jsp") === -1) {
            category = navElement.targetUrl;
          } else {
            category = navElement?.targetUrl?.substring(
              0,
              navElement?.targetUrl?.indexOf(".jsp") + 4
            );
            if (category === "/content/category.jsp") {
              category = navElement.targetUrl;
            }
          }
          const lowerCaseDisplayName =
            navElement &&
            navElement.displayName &&
            typeof navElement.displayName === "string"
              ? navElement.displayName.toLowerCase()
              : "";
          const enabledLink =
            lowerCaseDisplayName === "sale"
              ? false
              : catalogLinksToEnable.includes(category);

          const navLabel =
            navElement &&
            navElement.displayName &&
            typeof navElement.displayName === "string"
              ? navElement.displayName?.replace(/<br>/g, "")
              : "";

          const isHiddenConciergeLink =
            isConcierge &&
            (navElement?.targetUrl?.includes("wish-list.jsp") ||
              navElement?.displayName?.toLowerCase() === "registry");

          const hasNoSubCategories = (
            navElement as NavigationElement
          )?.childCategories?.every?.(child => !child?.childCategories?.length);

          const hasNoSecondarySubCategories = (
            navElement as NavigationElement
          )?.childCategories?.every?.(
            child =>
              !child?.childCategories?.length ||
              (child as NavigationElement)?.childCategories?.every?.(
                secondaryChild => !secondaryChild?.childCategories?.length
              )
          );

          return !isHiddenConciergeLink &&
            !(
              navElement?.targetUrl?.includes("gift-registry") &&
              (brand === "TN" ||
                userType === "CONTRACT" ||
                userType === "TRADE")
            ) ? (
            <div
              key={navElement.id}
              data-navigation-account-item-id={
                navElement.targetUrl === "/my-account/wish-list.jsp"
                  ? "wish-list"
                  : navElement.id
              }
              className={cn([
                navElement &&
                navElement.displayName &&
                typeof navElement.displayName === "string" &&
                navElement?.displayName?.toUpperCase() ===
                  CATALOG_SALE_NAME.toUpperCase() &&
                !isDarkMode
                  ? `flex !-mt-[8px] pt-[8px] no-underline decoration-[${
                      isDarkMode ? "rgba(255,255,255,0)" : "rgba(0,0,0,0)"
                    }] underline-offset-[5px] !hover:underline !hover:color-[#CA2022]`
                  : `flex !-mt-[8px] pt-[8px] no-underline decoration-[${
                      isDarkMode ? "rgba(255,255,255,0)" : "rgba(0,0,0,0)"
                    }] underline-offset-[5px] !hover:underline !hover:color-[${
                      isDarkMode ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)"
                    }]`
              ])}
            >
              {navElement.id === CATALOG_NAV_CONCEPTS_ID ? (
                <div className="!mt-[-4px]">
                  <div
                    data-testid="catalog-nav-concepts"
                    className={`flex w-full h-full items-center cursor-pointer`}
                  >
                    <div>
                      <RHLogo
                        onClick={e => e.preventDefault()}
                        brand={brand}
                        id={"nav-logo-img"}
                        primary={isDarkMode ? true : false}
                        imgStyle={{
                          height: styles?.RHLogoHeight || "10px",
                          width: "auto"
                        }}
                        logoDirName={
                          (styles?.brandDropdownLogosDirName || "") as string
                        }
                      />
                    </div>
                    <div>
                      <RHRArrowIcon
                        className="ml-[8px] w-2 text-[8px] transition-transform duration-300 rotate-90 "
                        strokeColor={themeColor}
                        style={
                          isSelected
                            ? { transform: "rotate(270deg)" }
                            : {
                                fontSize: theme.spacing(
                                  (styles?.rhLogoArrowFont as number) || 1
                                ),
                                fill: "none"
                              }
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : enabledLink ||
                (navElement?.childCategories?.length ?? 0) === 0 ? (
                <NavigationLink
                  classes="flex items-end h-[18px] te"
                  to={navElement.targetUrl}
                  style={disabled ? { pointerEvents: "none" } : undefined}
                  target={navElement?.linkType === "none" ? "_blank" : "_self"}
                >
                  {navLabel?.includes("Wish List") && (
                    <RHHeartIcon color={color} />
                  )}
                  <Typography
                    variant="caption"
                    style={{
                      color:
                        navElement &&
                        navElement.displayName &&
                        typeof navElement.displayName === "string" &&
                        navElement?.displayName?.toUpperCase() ===
                          CATALOG_SALE_NAME.toUpperCase()
                          ? CATALOG_SALE_COLOR
                          : themeColor,
                      fontWeight: 400,
                      textTransform: "uppercase",
                      lineHeight: "1.75rem"
                    }}
                    className={
                      isConcierge
                        ? `text-[11px] h-[13px]`
                        : "md:text-[12px] lg:text-[13px] ipadLg:text-[14px] h-[18px] text-[1.1667rem] tracking-[0.05em] font-primary-rhc-roman"
                    }
                    dangerouslySetInnerHTML={{
                      __html: navLabel?.includes("Wish List")
                        ? navLabel.slice(7, navLabel.length)
                        : navLabel
                    }}
                  />
                </NavigationLink>
              ) : (
                <Popover
                  open={isSelected}
                  onOpenChange={open => {
                    if (!open) {
                      onCategoryDrawerClose?.();
                    }
                  }}
                >
                  <PopoverTrigger
                    className="data-[state=open]:underline p-0 text-black"
                    onClick={e => {
                      handleNavItemClick(e, navElement, enabledLink);
                    }}
                  >
                    <NavigationLink
                      classes="group flex items-end h-[18px]"
                      to={navElement.targetUrl}
                      style={disabled ? { pointerEvents: "none" } : undefined}
                      noLink={true}
                    >
                      {navLabel?.includes("Wish List") && (
                        <RHHeartIcon color={color} />
                      )}
                      <Typography
                        variant="caption"
                        style={{
                          color:
                            navElement &&
                            navElement.displayName &&
                            typeof navElement.displayName === "string" &&
                            navElement?.displayName?.toUpperCase() ===
                              CATALOG_SALE_NAME.toUpperCase()
                              ? CATALOG_SALE_COLOR
                              : themeColor,
                          fontWeight: 400,
                          textTransform: "uppercase",
                          lineHeight: "1.75rem"
                        }}
                        className={
                          isConcierge
                            ? `text-[11px] h-[13px]`
                            : "md:text-[12px] lg:text-[13px] ipadLg:text-[14px] h-[18px] text-[1.1667rem] tracking-[0.05em] font-primary-rhc-roman"
                        }
                        dangerouslySetInnerHTML={{
                          __html: navLabel?.includes("Wish List")
                            ? navLabel.slice(7, navLabel.length)
                            : navLabel
                        }}
                      />
                    </NavigationLink>
                  </PopoverTrigger>
                  <PopoverContent
                    align={
                      navElement?.displayName?.toLowerCase() === "sale"
                        ? "end"
                        : isMdToLg &&
                          (navElement?.displayName?.toLowerCase() === "rugs" ||
                            navElement?.displayName?.toLowerCase() ===
                              "decor" ||
                            navElement?.displayName?.toLowerCase() ===
                              "interior design")
                        ? "end"
                        : "start"
                    }
                    className={cn("bg-rh-buff p-10 mt-[3px]", {
                      "w-[680px]":
                        navElement?.displayName?.toLowerCase() === "sale" &&
                        !hasNoSecondarySubCategories,
                      "w-[222px]": hasNoSubCategories,
                      "w-[516px]":
                        !(
                          navElement?.displayName?.toLowerCase() === "sale" &&
                          !hasNoSecondarySubCategories
                        ) && !hasNoSubCategories
                    })}
                  >
                    {navElement?.displayName?.toLowerCase() === "sale" ? (
                      <RHRSaleNavigation
                        selectedCatalogNavItem={navElement}
                        onCategoryDrawerClose={onCategoryDrawerClose}
                      />
                    ) : (
                      <CatalogNavigationDetails
                        selectedCatalogNavItem={navElement ?? {}}
                        onCategoryDrawerClose={onCategoryDrawerClose}
                        hasNoSubCategories={hasNoSubCategories}
                      />
                    )}
                  </PopoverContent>
                </Popover>
              )}
            </div>
          ) : null;
        })}
      </ul>
    );
  }

  return (
    <ul
      className={cn([
        `flex flex-nowrap ${isAem ? "w-1/2 mx-auto my-[14px]" : "!my-0"} p-0`,
        justifyClasses?.[justify as keyof typeof justifyClasses] ||
          "justify-between"
      ])}
      role="menubar"
      aria-label="Main"
    >
      {showBackToRH && backToHomeLink && (
        <span
          className={`cursor-pointer absolute left-[60px] xl:left-[100px] flex items-center top-[-5px]`}
        >
          <RHChevronIcon htmlColor={isDarkMode ? "#fff" : "#000"} />
          <a
            href={backToHomeLink}
            className={`ml-[5px] ${
              isDarkMode ? "text-white" : "text-black"
            } no-underline hidden hover:underline ${
              isDarkMode ? "hover:text-white" : "hover:text-black"
            } md:inline-block`}
          >
            {backtoRHLabel}
          </a>
        </span>
      )}
      {navigationList?.length > 0
        ? navigationList?.map((navElement, index) => {
            const isSelected =
              selectedNavItem?.displayName == navElement?.displayName;
            let category;

            if (navElement?.targetUrl?.indexOf(".jsp") === -1) {
              category = navElement.targetUrl;
            } else {
              category = navElement?.targetUrl?.substring(
                0,
                navElement?.targetUrl?.indexOf(".jsp") + 4
              );
              if (category === "/content/category.jsp") {
                category = navElement.targetUrl;
              }
            }
            const lowerCaseDisplayName =
              navElement &&
              navElement.displayName &&
              typeof navElement.displayName === "string"
                ? navElement.displayName.toLowerCase()
                : "";
            const enabledLink =
              lowerCaseDisplayName === "sale"
                ? false
                : catalogLinksToEnable.includes(category);

            const navLabel =
              navElement &&
              navElement.displayName &&
              typeof navElement.displayName === "string"
                ? navElement.displayName?.replace(/<br>/g, "")
                : "";

            const isHiddenConciergeLink =
              isConcierge &&
              (navElement?.targetUrl?.includes("wish-list.jsp") ||
                navElement?.displayName?.toLowerCase() === "registry");

            return !isHiddenConciergeLink &&
              !(
                navElement?.targetUrl?.includes("gift-registry") &&
                (brand === "TN" ||
                  userType === "CONTRACT" ||
                  userType === "TRADE")
              ) ? (
              <>
                <div
                  id={`container-rhrheader-rhr-catalogNav_catalogNav-${navElement.id}`}
                  tabIndex={0}
                  aria-label={navElement.displayName || undefined}
                  aria-expanded={isSelected}
                  key={`catalogNav-${navElement.id}`}
                  data-navigation-account-item-id={
                    navElement.targetUrl === "/my-account/wish-list.jsp"
                      ? "wish-list"
                      : navElement.id
                  }
                  onKeyDown={handleKeyboardPressWithEvent(e =>
                    handleNavItemClick(e, navElement, enabledLink)
                  )}
                  onClick={e => handleNavItemClick(e, navElement, enabledLink)}
                  className={cn([
                    navElement &&
                    navElement.displayName &&
                    typeof navElement.displayName === "string" &&
                    navElement?.displayName?.toUpperCase() ===
                      CATALOG_SALE_NAME.toUpperCase() &&
                    !isDarkMode
                      ? `flex !-mt-[8px] pt-[8px] no-underline decoration-[${
                          isDarkMode ? "rgba(255,255,255,0)" : "rgba(0,0,0,0)"
                        }] underline-offset-[5px] !hover:underline !hover:color-[#CA2022]`
                      : `flex !-mt-[8px] pt-[8px] no-underline decoration-[${
                          isDarkMode ? "rgba(255,255,255,0)" : "rgba(0,0,0,0)"
                        }] underline-offset-[5px] !hover:underline !hover:color-[${
                          isDarkMode ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)"
                        }]`
                  ])}
                  ref={el => (navListRef.current[index] = el)}
                  style={
                    isSelected
                      ? {
                          textDecoration: "underline",
                          textDecorationColor: themeColor
                        }
                      : undefined
                  }
                >
                  {navElement.id === CATALOG_NAV_CONCEPTS_ID ? (
                    <div className="!mt-[-4px]">
                      <div
                        data-testid="catalog-nav-concepts"
                        className={`flex w-full h-full items-center cursor-pointer`}
                      >
                        <div>
                          <RHLogo
                            onClick={e => e.preventDefault()}
                            brand={brand}
                            id={"nav-logo-img"}
                            primary={isDarkMode ? true : false}
                            imgStyle={{
                              height: styles?.RHLogoHeight || "10px",
                              width: "auto"
                            }}
                            logoDirName={
                              (styles?.brandDropdownLogosDirName ||
                                "") as string
                            }
                          />
                        </div>
                        <div>
                          <RHRArrowIcon
                            className="ml-[8px] w-2 text-[8px] transition-transform duration-300 rotate-90 "
                            strokeColor={themeColor}
                            style={
                              isSelected
                                ? { transform: "rotate(270deg)" }
                                : {
                                    fontSize: theme.spacing(
                                      (styles?.rhLogoArrowFont as number) || 1
                                    ),
                                    fill: "none"
                                  }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <NavigationLink
                      classes="flex items-end h-[18px]"
                      to={navElement.targetUrl}
                      noLink={!enabledLink}
                      style={disabled ? { pointerEvents: "none" } : undefined}
                    >
                      {navLabel?.includes("Wish List") && (
                        <RHHeartIcon color={color} />
                      )}
                      <Typography
                        variant="caption"
                        style={{
                          color:
                            navElement &&
                            navElement.displayName &&
                            typeof navElement.displayName === "string" &&
                            navElement?.displayName?.toUpperCase() ===
                              CATALOG_SALE_NAME.toUpperCase()
                              ? CATALOG_SALE_COLOR
                              : themeColor,
                          fontWeight: 400,
                          textTransform: "uppercase",
                          lineHeight: "1.75rem"
                        }}
                        className={
                          isConcierge
                            ? `text-[11px] h-[13px]`
                            : "md:text-[12px] lg:text-[13px] ipadLg:text-[14px] h-[18px] text-[1.1667rem] tracking-[0.05em] font-primary-rhc-roman"
                        }
                        dangerouslySetInnerHTML={{
                          __html: navLabel?.includes("Wish List")
                            ? navLabel.slice(7, navLabel.length)
                            : navLabel
                        }}
                      />
                    </NavigationLink>
                  )}
                </div>
                <Popper
                  open={isSelected}
                  anchorEl={navListRef.current[index]}
                  placement={
                    selectedNavItem?.displayName?.toLowerCase() === "sale"
                      ? "bottom-end"
                      : isMdToLg &&
                        (selectedNavItem?.displayName?.toLowerCase() ===
                          "rugs" ||
                          selectedNavItem?.displayName?.toLowerCase() ===
                            "decor" ||
                          selectedNavItem?.displayName?.toLowerCase() ===
                            "interior design")
                      ? "bottom-end"
                      : "bottom-start"
                  }
                  disablePortal={true}
                  modifiers={[
                    {
                      name: "flip",
                      enabled: false
                    }
                  ]}
                  className={`ml-[${
                    selectedNavItem?.displayName?.toLowerCase() === "sale"
                      ? "unset"
                      : "-20px"
                  }] ${
                    isConcierge ? "mt-[3px]" : "mt-0"
                  } bg-[#f9f7f4] shadow-[0px_2px_4px_rgba(0,0,0,0.1)] z-40 ${
                    selectedNavItem?.displayName?.toLowerCase() === "sale" &&
                    !hasNoSecondarySubCategories
                      ? "w-[680px]"
                      : hasNoSubCategories
                      ? "w-[222px]"
                      : "w-[516px]"
                  } p-10 absolute top-0 left-0 transform ${
                    isSelected ? "" : "hidden"
                  }`}
                  transition
                  tabIndex={-1}
                >
                  {({ TransitionProps }) => (
                    <Fade
                      {...TransitionProps}
                      timeout={RHR_FADE_TIMEOUT_DURATION_MIN}
                    >
                      <div>
                        <ClickAwayListener
                          onClickAway={() => {
                            isSelected && onCategoryDrawerClose();
                          }}
                        >
                          <div>
                            {navElement?.displayName?.toLowerCase() ===
                            "sale" ? (
                              <RHRSaleNavigation
                                selectedCatalogNavItem={navElement}
                                onCategoryDrawerClose={onCategoryDrawerClose}
                                isSelected={isSelected}
                              />
                            ) : (
                              <CatalogNavigationDetails
                                selectedCatalogNavItem={navElement ?? {}}
                                onCategoryDrawerClose={onCategoryDrawerClose}
                                hasNoSubCategories={hasNoSubCategories}
                                isSelected={isSelected}
                              />
                            )}
                          </div>
                        </ClickAwayListener>
                      </div>
                    </Fade>
                  )}
                </Popper>
              </>
            ) : null;
          })
        : null}
    </ul>
  );
};

export default memoize(CatalogNavigation);
